import { memo } from 'react';
import { Helmet } from 'react-helmet';

import { BrandName } from '../../types/brand';

const metaInfo: Record<BrandName, any> = {
  officeservice: {
    title: 'ОФИС СЕРВИС',
    facebook: {
      url: 'https://oficeservice.wavepoint.ru',
      title: 'ОФИС СЕРВИС',
      image: 'https://oficeservice.wavepoint.ru/brands/officeservice/cover.svg',
    },
    twitter: {
      domain: 'oficeservice.wavepoint.ru',
      url: 'https://oficeservice.wavepoint.ru',
      title: 'ОФИС СЕРВИС',
      image: 'https://oficeservice.wavepoint.ru/brands/officeservice/cover.svg',
    },
  },
  tagpoint: {
    title: 'TAGPOINT.',
    image: 'https://app.tagpoint.co.uk/brands/tagpoint/cover.svg',
    facebook: {
      url: 'https://app.tagpoint.co.uk/login/',
      title: 'TAGPOINT.',
      image: 'https://app.tagpoint.co.uk/brands/tagpoint/cover.svg',
    },
    twitter: {
      domain: 'app.tagpoint.co.uk/',
      url: 'https://app.tagpoint.co.uk/login/',
      title: 'TAGPOINT.',
      image: 'https://app.tagpoint.co.uk/brands/tagpoint/cover.svg',
    },
  },
  teorema: {
    title: 'ТЕОРЕМА',
    image: 'https://sbcs.ru/brands/teorema/cover.svg',
    facebook: {
      url: 'https://sbcs.ru',
      title: 'ТЕОРЕМА',
      image: 'https://sbcs.ru/brands/teorema/cover.svg',
    },
    twitter: {
      domain: 'sbcs.ru',
      url: 'https://sbcs.ru',
      title: 'ТЕОРЕМА',
      image: 'https://sbcs.ru/brands/teorema/cover.svg',
    },
  },
  waveservice: {
    title: 'WAVESERVICE.',
    image: 'https://app.waveservice.ru/brands/waveservice/cover.svg',
    url: 'https://app.waveservice.ru/login/',
    facebook: {
      url: 'https://app.waveservice.ru/login/',
      title: 'WAVESERVICE.',
      image: 'https://app.waveservice.ru/brands/waveservice/cover.svg',
    },
    twitter: {
      domain: 'waveservice.ru',
      url: 'https://app.waveservice.ru/login/',
      title: 'WAVESERVICE.',
      image: 'https://app.waveservice.ru/brands/waveservice/cover.svg',
    },
  },
  waveserviceEN: {
    title: 'WAVESERVICE.',
    facebook: {
      url: 'https://wave-service.com',
      title: 'WAVESERVICE.',
      image: 'https://wave-service.com/brands/tagpoint/cover.svg',
    },
    twitter: {
      domain: 'wave-service.com',
      url: 'https://wave-service.com',
      title: 'WAVESERVICE.',
      image: 'https://wave-service.com/brands/tagpoint/cover.svg',
    },
  },
  landia: {
    title: 'G-Landia Eco',
    facebook: {
      url: 'https://g-landia.com/',
      title: 'G-LANDIA Eco',
      image: 'https://g-landia.com/brands/glandia/cover.svg',
    },
    twitter: {
      domain: 'g-landia.com',
      url: 'https://g-landia.com/',
      title: 'G-LANDIA',
      image: 'https://g-landia.com/brands/glandia/cover.svg',
    },
  },
  zabotaapp: {
    title: 'С заботой о Вас!',
    facebook: {
      url: 'https://https://zabota-nlmk.ru/login/',
      title: 'С заботой о вас',
      image: 'https://zabota-nlmk.ru/brands/zabotaapp/cover.png',
    },
  },
  suop: {
    title: 'С заботой о Вас!',
    facebook: {
      url: 'https://zabota-nlmk.ru/login/',
      title: 'С заботой о вас',
      image: 'https://zabota-nlmk.ru/brands/zabotaapp/cover.png',
    },
  },
  gsp: {
    title: 'МОЙ ОФИС',
    image: 'https://gsp-mf-service.ru/brands/gsp/cover.svg',
    url: 'https://gsp-mf-service.ru/login/',
    facebook: {
      url: 'https://gsp-mf-service.ru/login/',
      title: 'МОЙ ОФИС',
      image: 'https://gsp-mf-service.ru/brands/gsp/cover.svg',
    },
  },
  'code-service': {
    title: 'CODE\u00A0SERVICE',
    image: 'https://code-service.ru/brands/code-service/cover.png',
    url: 'https://code-service.ru/login/',
    facebook: {
      url: 'https://code-service.ru/login/',
      title: 'CODE\u00A0SERVICE',
      image: 'https://code-service.ru/brands/code-service/cover.png',
    },
  },
  nornikel: {
    title: 'ЗАБОТА-24',
    image: 'https://zabota-24.ru/brands/zabota-24/cover.png',
    url: 'https://zabota-24.ru/login/',
    facebook: {
      url: 'https://zabota-24.ru/login/',
      title: 'ЗАБОТА-24',
      image: 'https://zabota-24.ru/brands/zabota-24/cover.png',
    },
  },
  elma: {
    title: 'ЭЛМА-Сервис',
    image: 'https://app.elmagroup.ru/brands/elma/cover.png',
    url: 'https://app.elmagroup.ru/login/',
    facebook: {
      url: 'https://app.elmagroup.ru/login/',
      title: 'ЭЛМА-Сервис',
      image: 'https://app.elmagroup.ru/brands/elma/cover.png',
    },
  },
  rkvr: {
    title: 'RUKVER Service',
    image: 'https://rkvr.ru/brands/rkvr/cover.png',
    url: 'https://rkvr.ru/login/',
    facebook: {
      url: 'https://rkvr.ru/login/',
      title: 'RUKVER Service',
      image: 'https://rkvr.ru/brands/rkvr/cover.png',
    },
  },
  myoffice20: {
    title: 'Мой офис 2.0',
    image: 'https://myoffice20.ru/brands/myoffice20/cover.png',
    url: 'https://myoffice20.ru/login/',
    facebook: {
      url: 'https://myoffice20.ru/login/',
      title: 'Мой офис 2.0',
      image: 'https://myoffice20.ru/brands/myoffice20/cover.png',
    },
  },
  'service-zabota': {
    title: 'Сервис Заботы',
    image: 'https://service-zabota.ru/brands/service-zabota/cover.png',
    url: 'https://service-zabota.ru/login/',
    facebook: {
      url: 'https://service-zabota.ru/login/',
      title: 'Сервис Заботы',
      image: 'https://service-zabota.ru/brands/service-zabota/cover.png',
    },
  },
  'zabota-mr-group': {
    title: 'MR Забота',
    image: 'https://zabota.mr-group.ru/brands/zabota-mr-group/cover.png',
    url: 'https://zabota.mr-group.ru/login/',
    facebook: {
      url: 'https://zabota.mr-group.ru/login/',
      title: 'MR Забота',
      image: 'https://zabota.mr-group.ru/brands/zabota-mr-group/cover.png',
    },
  },
};

interface Props {
  brand: BrandName;
}

const MetaTags = ({ brand }: Props) => {
  const metaTagsData = metaInfo[brand];
  return (
    <Helmet>
      <title>{metaTagsData.title}</title>
      <meta name="description" content={t('meta.description')} />
      <meta property="og:description" content={t('meta.description')} />
      <meta property="og:type" content="website" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="canonical" href={metaTagsData.facebook.url} />
      {metaTagsData.facebook && <meta property="og:url" content={metaTagsData.facebook.url} />}
      {metaTagsData.facebook && <meta property="og:title" content={metaTagsData.facebook.title} />}
      {metaTagsData.facebook && <meta property="og:image" content={metaTagsData.facebook.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {metaTagsData.twitter && (
        <meta property="twitter:domain" content={metaTagsData.twitter.domain} />
      )}
      {metaTagsData.twitter && <meta property="twitter:url" content={metaTagsData.twitter.url} />}
      {metaTagsData.twitter && <meta name="twitter:title" content={metaTagsData.twitter.title} />}
      <meta name="twitter:description" content={t('meta.description')} />
      {metaTagsData.twitter && <meta name="twitter:image" content={metaTagsData.twitter.image} />}
    </Helmet>
  );
};

export default memo(MetaTags);
