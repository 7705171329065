import { brandName } from './constants';
import { BrandName } from '../types/brand';

type LoginOptions = {
  hasPassword: boolean;
  hasCheckbox: boolean;
};

interface BrandedData {
  clientApp: string;
  applicationName: string;
  applicationTitle: string;
  appScheme: string;
  APP_STORE: string;
  GOOGLE_PLAY: string;
  APP_GALLERY: string;
  shortLogo?: string;
  landing?: string;
  loginOptions: LoginOptions;
  defaultLang: 'ru' | 'en';
  poweredBy: 'waveservice' | 'tagpoint';
  poweredByIcon: 'ws-black' | 'tp-black';
}

const brandsData: Record<BrandName, BrandedData> = {
  officeservice: {
    clientApp: 'office.service',
    applicationName: 'ОФИС\u00A0СЕРВИС',
    applicationTitle: 'ОФИС\u00A0СЕРВИС',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1492901162?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=office.service',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103398739',
    appScheme: '',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  tagpoint: {
    clientApp: 'tag.point',
    applicationName: 'TAGPOINT.',
    applicationTitle: 'TagPoint',
    APP_STORE: 'https://apps.apple.com/app/id1581587003',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=tag.point',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C107653695',
    shortLogo: '/brands/tagpoint/short-logo.webp',
    appScheme: 'tagpoint://open.app',
    defaultLang: 'en',
    poweredBy: 'tagpoint',
    poweredByIcon: 'tp-black',
    loginOptions: {
      hasCheckbox: true,
      hasPassword: true,
    },
  },
  teorema: {
    clientApp: 'smartbc',
    applicationName: 'ТЕОРЕМА',
    applicationTitle: 'ТЕОРЕМА',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1021199196?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=ru.sbcs',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103397531',
    shortLogo: '/brands/teorema/short-logo.webp',
    appScheme: 'teorema://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  waveservice: {
    clientApp: 'smart.space',
    applicationName: 'WAVESERVICE.',
    applicationTitle: 'Wave\u00A0Service',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1139141510?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=space.smart',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    shortLogo: '/brands/waveservice/short-logo.webp',
    landing: 'waveservice.ru',
    appScheme: 'waveservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  waveserviceEN: {
    clientApp: 'smart.space',
    applicationName: 'WAVESERVICE.',
    applicationTitle: 'Wave\u00A0Service',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1139141510?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=space.smart',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    shortLogo: '/brands/waveservice/short-logo.webp',
    appScheme: '',
    defaultLang: 'en',
    poweredBy: 'tagpoint',
    poweredByIcon: 'tp-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  landia: {
    clientApp: 'g.landia',
    applicationName: 'G-LANDIA Eco',
    applicationTitle: 'G-LANDIA Eco',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id1615389766?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=g.landia.eco',
    APP_GALLERY: 'https://appgallery.huawei.com/#/app/C103372073',
    appScheme: '',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  zabotaapp: {
    clientApp: 'zabota.app',
    applicationName: 'С заботой о Вас!',
    applicationTitle: 'С заботой о Вас!',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6449288858?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=zabota.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C108466363',
    appScheme: 'zabotaapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  suop: {
    clientApp: 'zabota.app',
    applicationName: 'С заботой о Вас!',
    applicationTitle: 'С заботой о Вас!',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6449288858?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=zabota.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C108466363',
    appScheme: 'zabotaapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  gsp: {
    clientApp: 'gsp.service',
    applicationName: 'Мой\u00A0офис',
    applicationTitle: 'Мой\u00A0офис',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6466608312?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=gsp.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C109216723',
    shortLogo: '/brands/gsp/short-logo.webp',
    appScheme: 'gspservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  'code-service': {
    clientApp: 'code.service',
    applicationName: 'CODE\u00A0SERVICE',
    applicationTitle: 'CODE\u00A0SERVICE',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6480408957?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=code.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C110697471',
    shortLogo: '/brands/code-service/short-logo.webp',
    appScheme: 'codeservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  nornikel: {
    clientApp: 'nrnkl.app',
    applicationName: 'Забота-24',
    applicationTitle: 'Забота-24',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6504757959?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=nrnkl.app',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C111407111',
    shortLogo: '/brands/nornikel/short-logo.webp',
    appScheme: 'nrnklapp://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: false,
    },
  },
  elma: {
    clientApp: 'elma.service',
    applicationName: 'ЭЛМА-Сервис',
    applicationTitle: 'ЭЛМА-Сервис',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6587573250?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=elma.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C111690645',
    shortLogo: '/brands/elma/short-logo.webp',
    appScheme: 'elmaservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  rkvr: {
    clientApp: 'rukver.service',
    applicationName: 'RUKVER Service',
    applicationTitle: 'RUKVER Service',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6642703495?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=rukver.service',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C111841473',
    shortLogo: '/brands/rkvr/short-logo.webp',
    appScheme: 'rukverservice://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  myoffice20: {
    clientApp: 'myff.fun',
    applicationName: 'Мой офис 2.0',
    applicationTitle: 'Мой офис 2.0',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6737550354?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=myff.fun',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C112456777',
    shortLogo: '/brands/myoffice20/short-logo.webp',
    appScheme: 'myfffun://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  'service-zabota': {
    clientApp: 'service.zabota',
    applicationName: 'Сервис Заботы',
    applicationTitle: 'Сервис Заботы',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6744049113?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=service.zabota',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C113860333',
    shortLogo: '/brands/service-zabota/short-logo.webp',
    appScheme: 'servicezabota://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
  'zabota-mr-group': {
    clientApp: 'mr.zabota',
    applicationName: 'MR Забота',
    applicationTitle: 'MR Забота',
    APP_STORE: 'https://itunes.apple.com/ru/app/smart-space/id6744387102?l=en&mt=8',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=mr.zabota',
    APP_GALLERY: 'https://appgallery.huawei.com/app/C113917577',
    shortLogo: '/brands/zabota-mr-group/short-logo.webp',
    appScheme: 'mrzabota://open.app',
    defaultLang: 'ru',
    poweredBy: 'waveservice',
    poweredByIcon: 'ws-black',
    loginOptions: {
      hasCheckbox: false,
      hasPassword: true,
    },
  },
};

export default brandsData[brandName];
